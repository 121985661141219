
    .app-wrapper {
        display: grid;

        grid-template-areas: "header" "nav" "content " "footer";
        /*grid-template-rows: 250px auto 100px;*/
        /*grid-auto-columns: 3fr 10fr 5fr;*/

        /*grid-auto-columns: 3fr 10fr 5fr;*/
    }

    /*.app-wrapper{*/
    /*    display: grid;*/

    /*    grid-template-areas: "n h r " "n c r " "f f f";*/


    /*    grid-template-rows: 250px auto 100px;*/
    /*    grid-auto-columns: 3fr 10fr 5fr;*/
    /*}*/

    .app-wrapper-content {

        grid-area: content;
        background: white;

        margin-left: 300px;
    }

    .app-wrapper-news_block{

        grid-area: content;
        background: white;

        /*margin-left: 300px;*/
    }

