/*.p{*/
/*    font-family: "Times New Roman";*/
/*    font-size: 16px;*/
/*    color: black;*/
/*    line-height: 2;*/
/*    text-indent: 20px;*/

/*}*/

.attention{
    font-family: "Times New Roman";
    font-size: 20px;
    color: red;
    line-height: 2;
    text-indent: 20px;

}

.selection{
    font-family: "Times New Roman";
    font-size: 20px;
    font-weight: bolder;
    color: black;
}

.headingSelection{
    font-family: "Times New Roman";
    color: black;
    font-weight: bolder;
}
.selectTitles{
    font-family: "Times New Roman";
    color: black;
    font-weight: bolder;

}

.ul{
    font-family: "Times New Roman";
    font-size: 16px;
    color: black;
}

.indent{
    font-family: "Times New Roman";
    font-size: 25px;
    color: black;
    font-weight: bolder;

}