.heading{
    color: black;
    margin-left: 70px;
    font-family: "Times New Roman";
}
.h4{
    font-family: "Times New Roman";
    margin-left: 70px;
    color: black;
}
.h5{
    font-family: "Times New Roman";
    margin-left: 70px;
    color: black;
}

.h2{
    font-family: "Times New Roman";
    margin-left: 70px;
    color: black;
}

.ul{
    font-family: "Times New Roman";
    color: black;
    margin-left: 10px;
    line-height: 30px;
    font-weight: bold;
    font-size: 20px;
}