.img {
    max-width: 100%;
}

.div_img {
    display: block;
    margin: 20px;
    max-width: 80%;
    max-height: 80%;

}

.div_img_september {
    display: block;
    margin: 20px;
    max-width: 50%;
    max-height: 50%;

}

