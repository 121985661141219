.footer{
    grid-area: footer;
    background: #bdbdbd;
    /*position:fixed;*/
    left:0px;
    bottom:0px;
    height:auto;
    width:100%;
    /*max-width: 100%;*/
    /*height: auto*/
}
.text{
    font-family: "Times New Roman";
    font-size: 20px;
    font-weight: bold;
    margin-left: 100px;
}