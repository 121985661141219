.p{
    font-family: "Times New Roman";
    font-size: 16px;
    color: black;
    line-height: 2;
    text-indent: 20px;

}
.h1{
    font-family: "Times New Roman";
}
.h3{
    font-family: "Times New Roman";
}