.h1{
    font-family: "Times New Roman";
}
.h3{
    font-family: "Times New Roman";
}

.text{
    font-family: "Times New Roman";
    font-size: 18px;
    font-weight: bold;
    /*margin-left: 100px;*/
}