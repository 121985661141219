/*.text{*/
/*    font-family: "Times New Roman";*/
/*    font-size: 16px;*/
/*    color: black;*/
/*    fon;*/
/*    !*line-height: 2;*!*/
/*    !*text-indent: 20px;*!*/
/*    !*vertical-align: top;*!*/

/*}*/
.h1{
    font-family: "Times New Roman";
}
.h3{
    font-family: "Times New Roman";
}
.hr{
    /*border: none;*/
    border-top: 1px dashed #000; /* Параметры линии  */
    /*background-color: black;*/
    height: 3px;
}