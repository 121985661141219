/*.p{*/
/*    font-family: "Times New Roman";*/
/*    font-size: 16px;*/
/*    color: black;*/
/*    line-height: 2;*/
/*    text-indent: 20px;*/

/*}*/

.attention{
    font-family: "Times New Roman";
    font-size: 20px;
    color: red;
    line-height: 2;
    text-indent: 20px;
}

.selection{
    font-family: "Times New Roman";
    font-size: 17px;
    font-weight: bold;
    color: black;
}

.h1{
    font-family: "Times New Roman";
}
.h3{
    font-family: "Times New Roman";
}

.ul{
    font-family: "Times New Roman";
    font-size: 16px;
    color: black;

}