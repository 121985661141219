.p{
    font-family: "Times New Roman";
    font-size: 25px;
    color: black;
    line-height: 2;
    text-indent: 20px;

}

IMG.displayed {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 503px;
    height: 715px
}