
.h1{
    font-family: "Times New Roman";
    color: black;
}
.h1Color{
    font-family: "Times New Roman";
    color: red;
}

.h1Bold{
    font-family: "Times New Roman";
    font-weight: bolder;
}

.hr{
    background-color: black;
    height: 1px;
}

.img{
    max-width: 100%;
    height: auto;
    float: left;
    padding: 0 20px 20px 0;
    margin: 3px 50px 0px 0px;
}