
.header {
    grid-area: header;
    /*background: blue;*/
    /*width: 1650px;*/
    /*max-width: 100%;*/
    /*height: 100%;*/
    /*max-width: 100%;*/
}

.banner {
    max-width: 100%;
    height: 100%
}
