.content{
    grid-area: content;
    background: white;
    /*width: 10dvh;*/
    border: 0px solid black;
    margin: 20px;
    max-width: 100%;
}
.img{
    margin-top: 20px;
    margin-left: 20px;
    width: 20vh;
}

